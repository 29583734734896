
import autoSuggest from "./autoSuggest";
export default {
  props: ["element"],
  data() {
    return {
      search: "",
      lock: false,
      searchFocus: false,
    };
  },
  created() {
    this.$nuxt.$on("focusSearch", () => {
      let self = this;
      setTimeout(() => {
        try {
          self.$refs.search.focus();
        } catch (error) {
          console.error("no focus");
        }
      }, 50);
    });
  },
  mounted(){
    this.$nuxt.$on('closeMenu',_=>{
      this.searchFocus = false;
    })
  },
  watch: {
    "$store.state.sideFilters": {
      handler(v, o) {
        this.search = v.q;
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  components: {
    autoSuggest,
  },
  methods: {
    timeOut(a) {
      setTimeout(() => {
        a();
      }, 200);
    },
    doSearch() {
      try {
        this.$nuxt.$emit("closeMenu");
      } catch (error) {}
      let query = [];
      if (this.search) {
        query.push(`q=${this.search}`);
      }

      this.searchFocus = false;
      this.$router.push({ path: `/search/+${query.join("+")}` });
    },
  },
};
