
import { debounce } from "debounce";
export default {
  name: "autoSuggest",
  props: ["q", "focus"],
  data() {
    return {
      suggestions: [],
      suggestionsTotal: 0,
      loading: false,
      bounce: false,
      dirty: false,
    };
  },
  watch: {
    $route(to, from) {
      this.loading = false;
      this.suggestions = [];
    },
    q: function (v) {
      if (v && v != "" && v.length > 2) {
        this.getSuggest();
      }
    },
  },
  mounted() {},
  methods: {
    toItem(barcode) {
      this.suggestions = [];
      this.suggestionsTotal = 0;
      this.$router.push({ path: `/item/${barcode}` });
      this.$emit("clear");
    },
    getSuggest: debounce(function() {
      this.loading = true;
      this.dirty = true;
      let search = `&tags=${this.q}&q=${this.q}&limit=16`;
      this.suggestions = [];
      this.suggestionsTotal = 0;
      this.$hyperItem
        .getItems(`store_id=${this.$store.getters.store.store_id}${search}`)
        .then((ritems) => {
          if (ritems) {
            let temp = ritems;
            this.suggestions = temp.items.docs;
            this.suggestionsTotal = temp.items.total;
          }
          this.loading = false;
          this.bounce = false;
        });
    }, 500),
  },
};
